import React from 'react';
import detect from 'browser-detect';
import { useTranslation } from 'react-i18next';

import './AboutContent.styl';

const AboutContent = () => {
  const { t } = useTranslation('AboutContent');

  const { os, version, name } = detect();
  const capitalize = s =>
    s.substr(0, 1).toUpperCase() + s.substr(1).toLowerCase();

  const itemsPreset = () => {
    return [
      {
        name: t('Repository URL'),
        value: 'https://github.com/OHIF/Viewers/',
        link: 'https://github.com/OHIF/Viewers/',
      },
      {
        name: t('Latest Master Commits'),
        value: 'https://github.com/OHIF/Viewers/commits/master',
        link: 'https://github.com/OHIF/Viewers/commits/master',
      },
      {
        name: 'Version Number',
        value: process.env.VERSION_NUMBER,
      },
      {
        name: t('Build Number'),
        value: process.env.BUILD_NUM,
      },
      {
        name: t('Browser'),
        value: `${capitalize(name)} ${version}`,
      },
      {
        name: t('OS'),
        value: os,
      },
    ];
  };

  const renderTableRow = ({ name, value, link }) => (
    <tr key={name} style={{ backgroundColor: 'transparent' }}>
      <td>{name}</td>
      <td>
        {link ? (
          <a target="_blank" rel="noopener noreferrer" href={link}>
            {value}
          </a>
        ) : (
          value
        )}
      </td>
    </tr>
  );

  return (
    <div className="AboutContent" data-cy="about-modal">

    <div>
       <h3>En quant a</h3>
       <hr/>
       <p>El visor de la plataforma RAIM Cloud Agent, és una aplicació web de codi obert dissenyada per a visualitzar les proves realitzades a pacients i ofereix esquemes estàndard de visualització d'imatges i informes.</p>
       <p>El desenvolupament del visualitzador d'imatges de RAIM Cloud Agent es basa en el visualitzador de codi obert <a target="_blank" href="https://github.com/ohif/Viewers">OHIF viewer</a> i <a target="_blank" href="https://github.com/cornerstonejs/cornerstone">Cornerstone</a>.</p> 
       <h3>Contacta amb nosaltres</h3>
       <hr/>
       <p>Aquesta adaptació del visor OHIF per a la plataforma RAIM Cloud Agent i tots el productes RAIM han estat desenvolupats per l’equip de treball del Centre d'Imatge Mèdica Digital (CIMD) UDIAT-CD, Consorci Corporació Sanitària Parc Taulí de Sabadell (CCSPT).</p>
       <p>Si teniu cap pregunta o possibles col·laboracions futures, no dubteu en contactar amb: <a href="mailto:cimd@tauli.cat?subject=Contacte">Centre d'Imatge Mèdica Digital</a> de la CCSPT.</p>
       <div className="divTableMain">
          <a target="_blank" href="https://www.tauli.cat/udiat/cimd-presentacio">
          <img src="/rsc/img/logoCCSPT.svg" className="divTableCellImg"></img>
          </a>	
       </div>
    </div>
	  
      <div>
        <h3>{t('Version Information')}</h3>
        <table className="table table-responsive">
          <thead>
            <tr>
              <th>{t('Name')}</th>
              <th>{t('Value')}</th>
            </tr>
          </thead>
          <tbody>{itemsPreset().map(item => renderTableRow(item))}</tbody>
        </table>
      </div>
	  
      <div className="btn-group">
        <a
          className="btn btn-default"
          target="_blank"
          rel="noopener noreferrer"
          href="https://groups.google.com/forum/#!forum/cornerstone-platform"
        >
          {t('Visit the forum')}
        </a>
        {` `}
        <a
          className="btn btn-default"
          target="_blank"
          rel="noopener noreferrer"
          href="https://github.com/OHIF/Viewers/issues/new/choose"
        >
          {t('Report an issue')}
        </a>
        {` `}
        <a
          className="btn btn-default"
          target="_blank"
          rel="noopener noreferrer"
          href="http://ohif.org"
        >
          {t('More details')}
        </a>
      </div>
	  
    </div>
  );
};

export { AboutContent };
export default AboutContent;
